

export const api = {
    url: import.meta.env.VITE_API_LINK
}
export const apiUrl = import.meta.env.VITE_API_LINK

export default {
    api, apiUrl
}

