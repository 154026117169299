<template>

  <div class="wrapper">
    <div class="logo">
      <svg xmlns="http://www.w3.org/2000/svg" width="121" height="47" viewBox="0 0 121 47" fill="none">
        <rect x="1.5" y="1.5" width="118" height="43.0981" rx="21.5491" fill="#FFFDFA" stroke="#D9D6E5" stroke-width="3"/>
        <rect x="60.5304" y="5.9678" width="54.7453" height="34.2235" rx="17.1117" fill="#D9D6E5"/>
        <path d="M34.4635 28.158C34.4635 28.5582 34.3927 28.9877 34.2509 29.4463C34.1175 29.905 33.8923 30.3303 33.5754 30.7222C33.2669 31.1141 32.8625 31.4435 32.3621 31.7103C31.8618 31.9689 31.2489 32.0981 30.5234 32.0981H18.1777C17.9442 32.0981 17.7274 32.0564 17.5272 31.973C17.3271 31.8896 17.152 31.7771 17.0019 31.6353C16.8601 31.4852 16.7475 31.3101 16.6641 31.1099C16.5808 30.9098 16.5391 30.693 16.5391 30.4595V15.7997C16.5391 15.5746 16.5808 15.3619 16.6641 15.1618C16.7475 14.9617 16.8601 14.7866 17.0019 14.6365C17.152 14.4863 17.3271 14.3696 17.5272 14.2862C17.7274 14.2028 17.9442 14.1611 18.1777 14.1611H29.0599C29.4602 14.1611 29.8896 14.232 30.3483 14.3738C30.8069 14.5155 31.2322 14.7449 31.6241 15.0617C32.0244 15.3703 32.3538 15.7747 32.6123 16.275C32.8791 16.7754 33.0126 17.3883 33.0126 18.1138V18.7267C33.0126 19.3021 32.9167 19.9233 32.7249 20.5904C32.5331 21.2492 32.2245 21.8746 31.7993 22.4667C32.1662 22.6918 32.5081 22.9628 32.8249 23.2797C33.1502 23.5966 33.4337 23.9635 33.6755 24.3805C33.9173 24.7974 34.1091 25.2685 34.2509 25.7939C34.3927 26.3192 34.4635 26.8988 34.4635 27.5326V28.158ZM31.2114 27.5326C31.2114 27.1073 31.1446 26.7279 31.0112 26.3943C30.8778 26.0524 30.6902 25.7605 30.4483 25.5187C30.2065 25.2769 29.9147 25.0934 29.5728 24.9683C29.2309 24.8349 28.8473 24.7682 28.422 24.7682H21.0921V21.491H26.9585C27.3838 21.491 27.7674 21.4285 28.1093 21.3034C28.4512 21.17 28.743 20.9824 28.9849 20.7405C29.2267 20.4987 29.4102 20.211 29.5352 19.8774C29.6687 19.5356 29.7354 19.152 29.7354 18.7267V18.1138C29.7354 17.6468 29.5102 17.4133 29.0599 17.4133H19.7912V28.8459H30.5234C30.5818 28.8459 30.6527 28.8418 30.736 28.8334C30.8194 28.8251 30.8945 28.8001 30.9612 28.7584C31.0279 28.7167 31.0863 28.6458 31.1363 28.5457C31.1863 28.4457 31.2114 28.3081 31.2114 28.133V27.5326Z" fill="#D9D6E5"/>
        <path d="M54.9022 32.0981H51.65V27.6952H40.2174V32.0981H36.9652V23.1296C36.9652 21.8204 37.1903 20.6154 37.6407 19.5147C38.091 18.414 38.7164 17.4675 39.5169 16.6753C40.3174 15.8831 41.2639 15.266 42.3563 14.8241C43.4487 14.3821 44.6412 14.1611 45.9337 14.1611H53.2636C53.4887 14.1611 53.7014 14.2028 53.9015 14.2862C54.1016 14.3696 54.2768 14.4863 54.4269 14.6365C54.577 14.7866 54.6937 14.9617 54.7771 15.1618C54.8605 15.3619 54.9022 15.5746 54.9022 15.7997V32.0981ZM40.2174 24.443H51.65V17.4133H45.9337C45.8336 17.4133 45.621 17.43 45.2958 17.4633C44.9789 17.4884 44.6078 17.5592 44.1825 17.676C43.7656 17.7927 43.3236 17.972 42.8566 18.2138C42.3897 18.4557 41.9602 18.7892 41.5683 19.2145C41.1763 19.6398 40.8511 20.1735 40.5926 20.8156C40.3425 21.4493 40.2174 22.2207 40.2174 23.1296V24.443Z" fill="#D9D6E5"/>
        <path d="M86.475 15.1369C86.6001 15.4287 86.6376 15.7373 86.5876 16.0625C86.5375 16.3794 86.4041 16.6504 86.1873 16.8755L75.4551 28.846H86.45V32.0982H71.7902C71.4733 32.0982 71.1773 32.0106 70.9021 31.8355C70.6353 31.6521 70.4351 31.4144 70.3017 31.1225C70.1599 30.8307 70.1141 30.5263 70.1641 30.2094C70.2225 29.8925 70.3559 29.6174 70.5644 29.3839L81.3216 17.4134H70.3017V14.1612H84.9865C85.3034 14.1612 85.5952 14.2529 85.8621 14.4364C86.1373 14.6115 86.3416 14.845 86.475 15.1369Z" fill="#888888"/>
        <path d="M107.014 32.0982H103.762V27.6952H92.3289V32.0982H89.0767V23.1297C89.0767 21.8205 89.3019 20.6155 89.7522 19.5148C90.2025 18.414 90.8279 17.4676 91.6284 16.6754C92.429 15.8832 93.3754 15.2661 94.4678 14.8242C95.5602 14.3822 96.7527 14.1612 98.0452 14.1612H105.375C105.6 14.1612 105.813 14.2029 106.013 14.2863C106.213 14.3697 106.388 14.4864 106.538 14.6365C106.688 14.7866 106.805 14.9617 106.889 15.1619C106.972 15.362 107.014 15.5747 107.014 15.7998V32.0982ZM92.3289 24.4431H103.762V17.4134H98.0452C97.9452 17.4134 97.7325 17.4301 97.4073 17.4634C97.0904 17.4884 96.7193 17.5593 96.2941 17.6761C95.8771 17.7928 95.4351 17.9721 94.9682 18.2139C94.5012 18.4557 94.0717 18.7893 93.6798 19.2146C93.2879 19.6399 92.9627 20.1736 92.7042 20.8157C92.454 21.4494 92.3289 22.2208 92.3289 23.1297V24.4431Z" fill="#888888"/>
      </svg>
    </div>

    <div class="nav">
      <menu-footer />
    </div>

<!--    <div class="social">-->
<!--      <a :href="link.link" v-for="link of footerLinks" target="_blank">-->
<!--        <img :src="link.icon" alt="">-->

<!--      </a>-->
<!--    </div>-->
  </div>

</template>

<script>
import menuFooter from "../Blocks/Menus/MenuFooter.vue";
import {footerLinks} from "../assets/js/footerLinks.js";


export default {
  name: "FooterView.vue",
  components: {menuFooter},
  data() {
    return {
      footerLinks
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  margin-top: auto;
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  .logo {
    width: 100%;
    text-align: center;
  }
  .nav {

    text-align: center;
    width: auto;
    margin: 10px auto 0;
    align-items: center;
    display: flex;
  }

  .social {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;

    background-color: #dcdcdc;
    padding: 10px;
    border-radius: 20px;

    img {
      max-width: 80px;
      max-height: 30px;
      border-radius: 5px;
    }

  }

}
</style>